<template>
  <section
    class="maintenance-page"
    :style="{
      background: `${
        getCurrentBackgroundImageMaintenance
          ? `url(${getCurrentBackgroundImageMaintenance.url}) no-repeat center center fixed`
          : null
      }`,
    }"
  >
    <div class="maintenance-content">
      <img src="@/assets/images/logo-color.png" alt height="70" />
      <i class="fa fa-gear"></i>
      <p class="maintenance-text p-3">
        {{
          maintenanceInfo.title
            ? maintenanceInfo.title
            : "Modo mantenimiento activado"
        }}
      </p>
      <p class="maintenance-contact p-3">
        {{
          maintenanceInfo.description
            ? maintenanceInfo.description
            : "Cualquier comentario o duda: desarrollo@fluxsolar.cl"
        }}
      </p>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    this.getBackgroundImagesMaintenance();
    this.getMaintenanceInfo();
  },
  methods: {
    ...mapActions({
      getBackgroundImagesMaintenance: "auth/getBackgroundImagesMaintenance",
      getMaintenanceInfo: "auth/getMaintenanceInfo",
    }),
  },
  computed: {
    ...mapGetters({
      maintenanceInfo: "auth/maintenanceInfo",
      backgroundImagesMaintenance: "auth/backgroundImagesMaintenance",
    }),
    getCurrentBackgroundImageMaintenance() {
      if (
        this.backgroundImagesMaintenance &&
        this.backgroundImagesMaintenance.length
      ) {
        return this.backgroundImagesMaintenance[
          this.backgroundImagesMaintenance.length - 1
        ];
      }
      return null;
    },
  },
};
</script>
<style>
.maintenance-page {
  width: 100%;
  height: 100vh;
  background-color: #111;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.maintenance-content {
  text-align: center;
  color: #fff;
}

.maintenance-text {
  font-size: 34px;
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.6rem;
}

.maintenance-contact {
  font-size: 25px;
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.6rem;
}
</style>
